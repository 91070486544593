import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'

import Hero from './Hero'
import Content from './Content'
import Contact from './Contact'

const Employers = () => (
  <Layout>
    <SEO
      title="Product, Tech &amp; Design Recruiment"
      description="Looking for someone new? Find out how Pixel Pond can help you find the best people for your job."
    />
    <Hero />
    <Content />
    <Contact />
  </Layout>
)

export default Employers
