import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { screenMin, screenMax } from 'styles/helpers/responsive'

import { Link } from 'gatsby'

import { BORDER, COLOR, FONT, GTR } from 'styles/tokens'

import CATEGORIES from 'constants/jobs/categories'

const StyledList = styled(motion.ul)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${GTR.M};

  ${({ $wide }) => $wide && `grid-template-columns: repeat(4, 1fr);`}

  ${screenMax.s`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const StyledListItem = styled(motion.li)``

const StyledLink = styled(Link)`
  font-weight: ${FONT.WEIGHT.BOLD};
  text-align: center;
  display: block;

  ${screenMin.m`
    background-color: ${COLOR.WHITE};
    padding: ${GTR.M} ${GTR.S};
    border-radius: ${BORDER.RADIUS.M};
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      transform: translateY(-2px);
    }
  `}
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const CategoryList = ({ wide }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <>
      <StyledList
        $wide={wide}
        variants={container}
        initial="hidden"
        animate={controls}
        ref={ref}
      >
        {CATEGORIES.map(({ shortTitle, title, url }) => (
          <StyledListItem key={title} variants={item}>
            <StyledLink to={`/jobs${url}`}>{shortTitle || title}</StyledLink>
          </StyledListItem>
        ))}
      </StyledList>
    </>
  )
}

CategoryList.propTypes = {
  wide: PropTypes.bool,
}

CategoryList.defaultProps = {
  wide: false,
}

export default CategoryList
