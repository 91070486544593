import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { screenMax } from 'styles/helpers/responsive'

import Image from 'components/Image'
import CurveWrapper from 'components/CurveWrapper'
import { Button, Grid, Wrapper, H } from 'components/common'
import { COLOR, FONT, GTR } from 'styles/tokens'

const StyledP = styled.p`
  font-size: ${FONT.SIZE.L};
  margin-bottom: ${GTR.L};
`

const StyledImageGridItem = styled(Grid.Item)`
  ${screenMax.s`
    display: none;
  `}
`

const Contact = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pritesh-drawing-on-board.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={40}>
      <Wrapper container>
        <Grid>
          <Grid.Item spanFromM={5} style={{ alignSelf: 'center' }}>
            <H size="L">Let’s get started</H>
            <StyledP>Drop us an email and we will be in touch shortly</StyledP>
            <Button.White
              wide
              href="mailto:pritesh@pixel-pond.com"
              stretchMobile
            >
              Email Pritesh
            </Button.White>
          </Grid.Item>
          <StyledImageGridItem spanFromM={6} startColumnFromM={7}>
            <Image
              sizes={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
            />
          </StyledImageGridItem>
        </Grid>
      </Wrapper>
    </CurveWrapper>
  )
}

export default Contact
