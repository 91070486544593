import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import uk from 'images/flags/uk.png'
import usa from 'images/flags/usa.png'
import india from 'images/flags/india.png'
import spain from 'images/flags/spain.png'
import italy from 'images/flags/italy.png'
import china from 'images/flags/china.png'
import france from 'images/flags/france.png'
import germany from 'images/flags/germany.png'
import singapore from 'images/flags/singapore.png'
import sweden from 'images/flags/sweden.png'
import israel from 'images/flags/israel.png'
import japan from 'images/flags/japan.png'

import { H, Grid, Wrapper } from 'components/common'
import { COLOR, GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const FLAGS = [
  { image: uk, text: 'UK' },
  { image: usa, text: 'USA' },
  { image: india, text: 'India' },
  { image: spain, text: 'Spain' },
  { image: italy, text: 'Italy' },
  { image: china, text: 'China' },
  { image: france, text: 'France' },
  { image: germany, text: 'Germany' },
  { image: singapore, text: 'Singapore' },
  { image: sweden, text: 'Sweden' },
  { image: israel, text: 'Israel' },
  { image: japan, text: 'Japan' },
]

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const StyledFlagWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  width: calc(30% - 10px);

  && {
    margin-bottom: ${GTR.XL};
  }

  ${screenMax.m`
    width: calc(50% - 10px);
  `}
`

const StyledFlags = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  && {
    margin-bottom: -${GTR.XL};
  }
`

const StyledFlagImage = styled.img`
  margin-right: ${GTR.M};

  ${screenMax.m`
    width: 40px;
    margin-right: ${GTR.S};
  `}
`

const StyledFlagText = styled.p`
  color: ${COLOR.WHITE};
`

const StyledHeading = styled(H)`
  ${screenMax.s`
    display: none;
  `}
`

const Hero = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']} marginFromM={[0, 0, 'XXL']}>
          Something great starts here
        </H>
        <Grid gap="L" gapFromM="M">
          <Grid.Item spanFromM={5}>
            <StyledHeading size="L" color={COLOR.ACCENT.PRIMARY}>
              Scaling companies globally
            </StyledHeading>
            <p>
              We’re here to help everyone be their best, by offering products,
              services and solutions in the digital recruitment space that are
              upfront, honest, and fast. But importantly put people first.
            </p>
            <p>
              We provide an organised, considered, simple and human service - we
              help consultancies, start-ups and global tech companies hire top
              talent who bring products to life. We have placed exceptional
              digital specialists into expanding offices worldwide.
            </p>
          </Grid.Item>
          <Grid.Item spanFromM={6} startColumnFromM={7}>
            <StyledFlags
              variants={container}
              initial="hidden"
              animate={controls}
              ref={ref}
            >
              {FLAGS.map((flag) => (
                <StyledFlagWrapper key={flag.text} variants={item}>
                  <StyledFlagImage src={flag.image} width={50} alt="" />
                  <StyledFlagText>{flag.text}</StyledFlagText>
                </StyledFlagWrapper>
              ))}
            </StyledFlags>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Hero
