import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import discover from 'images/icons/discover-circle.png'
import materialize from 'images/icons/materialize-circle.png'
import deliver from 'images/icons/deliver-circle.png'

import Image from 'components/Image'
import CategoryList from 'components/CategoryList'
import { Wrapper, Grid, H } from 'components/common'
import { COLOR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const ImageWrapper = styled(Wrapper)`
  position: relative;
`

const StyledIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(60%, -40%);

  ${screenMax.m`
    width: 60px;
    transform: translate(10px, -20px);
  `}
`

const Content = () => {
  const { imageOne, imageTwo, imageThree } = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "team/nick-smiling-at-laptop.jpg" }) {
        ...fluidImage
      }
      imageTwo: file(relativePath: { eq: "clare-and-team-at-whiteboard.jpg" }) {
        ...fluidImage
      }
      imageThree: file(relativePath: { eq: "woman-with-glasses-on-sofa.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <>
      <Wrapper container>
        <Wrapper margin={[0, 0, 'L']} marginFromL={[0, 0, 'XXL']} centered>
          <H size="L">The Pixel Process</H>
        </Wrapper>
        <Grid margin={[0, 0, 'XL']} marginFromL={[0, 0, 'XXL']}>
          <Grid.Item spanFromL={6}>
            <ImageWrapper>
              <Image
                sizes={{
                  ...imageThree.childImageSharp.fluid,
                  aspectRatio: 3 / 2,
                }}
              />
              <StyledIcon src={discover} width={90} alt="" />
            </ImageWrapper>
          </Grid.Item>
          <Grid.Item spanFromL={5} startColumnFromL={8}>
            <H size="M">Discover</H>
            <p>
              You’ll be invited in for a chat with one of our experienced
              consultants. We’ll listen to what’s important to you in your
              search and recommend the best way of achieving your goals.
            </p>
            <p>
              The better we understand your business, the easier we can find the
              right people to join it. We think it’s important to get to know
              you, your ambitions as a company, and how you like to work before
              sending over potential candidates.
            </p>
            <p>
              We’ve been connecting the best people for years, and work with
              some of the most ambitious startups, agencies and tech companies
              around. We work on both permanent opportunities and freelance
              assignments.
            </p>
          </Grid.Item>
        </Grid>
        <Grid margin={[0, 0, 'XL']} marginFromL={[0, 0, 'XXL']}>
          <Grid.Item spanFromL={6}>
            <ImageWrapper>
              <Image
                sizes={{
                  ...imageTwo.childImageSharp.fluid,
                  aspectRatio: 3 / 2,
                }}
              />
              <StyledIcon src={materialize} width={90} alt="" />
            </ImageWrapper>
          </Grid.Item>
          <Grid.Item spanFromL={5} startColumnFromL={8}>
            <H size="M">Materialize</H>
            <p>
              Our collaborative team structure is something that makes us unique
              and enables us to work more efficiently and effectively. With us
              you’ll get your own remote Talent Strategist who will manage the
              recruitment process end-to-end to ensure the project is moving in
              the right direction. They will work with our team of recruiters
              who reach out to our global database and provide a personal
              candidate experience.
            </p>
            <p>
              We will be realistic and transparent throughout the process,
              making sure that as much information as possible is shared at
              every stage. But we also understand that your time is precious so
              won’t take up more than is essential.
            </p>
          </Grid.Item>
        </Grid>
        <Grid margin={[0, 0, 'XL']} marginFromL={[0, 0, 'XXL']}>
          <Grid.Item spanFromL={6}>
            <ImageWrapper>
              <Image
                sizes={{
                  ...imageOne.childImageSharp.fluid,
                  aspectRatio: 3 / 2,
                }}
              />
              <StyledIcon src={deliver} width={90} alt="" />
            </ImageWrapper>
          </Grid.Item>
          <Grid.Item spanFromL={5} startColumnFromL={8}>
            <H size="M">Deliver</H>
            <p>
              We allow you to be completely hands off - from screening
              candidates, co-ordinating interviews, through to assisting with
              the offer stage - we’ll do it all.
            </p>
            <p>
              Our contact doesn’t end after placement, we’ll check in with you
              and the candidate to see how things are going in the new role.
            </p>
            <p>
              We’re into long term relationships, so if there are small steps
              you need to take before reaching your goals, we’ll walk those with
              you. We’re not about quick fixes.
            </p>
          </Grid.Item>
        </Grid>
      </Wrapper>
      <Wrapper background={COLOR.BACKGROUND.PALE}>
        <Wrapper container>
          <H size="M" centered margin={[0, 0, 'L']} marginFromL={[0, 0, 'XL']}>
            Teams we recruit for
          </H>
          <CategoryList wide />
        </Wrapper>
      </Wrapper>
    </>
  )
}

export default Content
